import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import { Link } from "gatsby";
import Morpher from "../components/Morpher"

const Morf = () => {
    return (
      <Layout>
        <div className="index-container" > 
          <Helmet title={config.siteTitle} />
          <SEO />
          <Morpher />
        </div>
      </Layout>
    )
  }

  export default Morf;